import { ApolloError, gql as GraphQL, useQuery, useMutation } from "@apollo/client";

const QueryGraphQL = GraphQL`
    query getDashboardPageQuery($id: String!, $type: String!) {
        getProfile {
            _id
            settings {
                user {
                    username
                    discriminator
                    avatar
                }
            }
        }

        getGuild(id: $id, type: $type, guildReturn: "@config @guild") {
            config {
                _id
                data {
                    user {
                        id
                        level {
                            xp
                        }
                    }
                }
                settings {
                    leaderboard
                    premium 
                }
                modules
            }

            guild {
                id
                icon
                name
                members
                roles {
                    id
                    name
                    color
                }
            }
        }
    }
`;

const MutationGraphQL = GraphQL`
    mutation setGuildMutation($id: String!, $config: String!, $type: String!) {
        setGuildConfig(id: $id, config: $config, type: $type) {
            _id
        }
    }
`;

const Query = (id: Snowflake, type: string): { loading: boolean; error: ApolloError | undefined; data: any | null; refetch: Function } => {
    const { loading, error, data, refetch } = useQuery(QueryGraphQL, { variables: { id, type } });

    if (error) console.log(error);

    if (loading || error) return { loading: true, error, data: null, refetch };

    return { loading: false, error, data: { profile: data.getProfile, guild: data.getGuild.guild, config: data.getGuild.config }, refetch };
};

const Mutation = () => useMutation(MutationGraphQL)[0];

const Hook = { Query, Mutation };

export default Hook;
